<style scoped>
    @import '../assets/css/projectShow.scss';
    @import '../assets/css/projectGrids/project1__GridTemplates.scss';
    @import '../assets/css/projectGrids/project2__GridTemplates.scss';
    @import '../assets/css/projectGrids/project3__GridTemplates.scss';
    @import '../assets/css/projectGrids/project4__GridTemplates.scss';
    @import '../assets/css/projectGrids/project5__GridTemplates.scss';
    @import '../assets/css/projectGrids/project6__GridTemplates.scss';
    @import '../assets/css/projectGrids/project7__GridTemplates.scss';
</style>
<template>
    <div>
        <div class="projectShow__container panelProject"
            :data-bgcolor="project.dataColorInitial">
            <div class="projectShow__Name">
                <p class="projectShow__FirstPart"
                   :style="{'color':project?.projectNameColor}">{{project?.firstPartName}}</p>
                <p class="projectShow__SecondPart"
                   :style="{'color':project?.projectNameColor}">{{project?.secondPartName}}</p>
                <p class="projectShow__ThirdPart"
                   :style="{'color':project?.projectNameColor}">{{project?.thirdPartName}}</p>
            </div>
            <div class="projectShow__Description">
                <p :class="`projectShow__Description--date
                            projectShow__ProjectNameColor--Project${project?.id}
                            `"
                    >{{project?.description.date}}</p>
                <p class="projectShow__Description--location">{{project?.description.location}}</p>
            </div>
            <!-- Main Picture container -->
            <div class="spacer"
                :class="project.imageProjectPageMainContainer?.classGridTemplate"
                :data-bgcolor="project?.dataColorInitial"
                >
                <img
                    id="projectShow__imageProjectPageMain"
                    :src="getImgUrlImageProjects(project.slug, project.imageProjectPageMain.image)"
                    :class="`projectShow__image ${project.imageProjectPageMain.class} `"
                    >
            </div>
        </div>
        <!-- <div class="spacer panelProject"
                :data-bgcolor="project.dataColorInitial">
                <div class="projectShow__description">
                    <ProjectDescription
                    :descriptionProject="project.description"/>
                </div>
        </div> -->
        <div :class="`projectShow__corpus panelProject
            ${project.imagesProjectPageContainer.classGridTemplate}`"
            :data-bgcolor="project.dataColorInitial">
            <div
                v-for="img in project.imagesProjectPage"
                :key="img.id"
                :class="img.class">
                <scroll-parallax
                    :down="true"
                    :speed="img.dataParallax"
                    :direction="img.dataParallaxDirection"
                    class="picture">
                    <img  v-if="img.image"
                            :data-src="getImgUrlImageProjects(project.slug, img.image)"
                            :src="getImgUrlImageProjects(project.slug, img.image)"
                            :class="`lazy projectShow__picture  projectShow--project-${project.id}`"
                            :data-parallax="img.dataParallax">
                </scroll-parallax>
            </div>
        </div>
        <!-- NEXT PROJECT -->
        <div class="panelProject
                    pictureNext__ProjectPage
                    nextProject"
                :data-bgcolor="project.nextProject.dataColor"
                :id="project.nextProject.id">
                <!-- <PageTransitionProjects
                    :imageMain="project.imageMain"
                    :nextProject="project.nextProject"
                    :currentProjectSlug="slug"/> -->
                    <!-- Content -->
                <router-link :to="{ name:'project.show',
                                    params:{
                                            id:project.nextProject.id,
                                            slug: project.nextProject.slug
                                            }
                                    }"
                                    :class="`projectShow_nextProject${project.nextProject.id}_routerLink`"
                                    @click="test('')">
                                    <p id="projectShow_nav--next"
                                        :class="`projectShow_nextProject${project.nextProject.id}_nav--next`">
                                        NEXT
                                    </p>
                                    <p id="projectShow_nav--firstPartName"
                                       :class="`projectShow_nextProject${project.nextProject.id}_nav--firstPartName`">
                                       {{project.nextProject?.firstPartName}}
                                    </p>
                                    <p id="projectShow_nav--secondPartName"
                                       :class="`projectShow_nextProject${project.nextProject.id}_nav--secondPartName`">
                                       {{project.nextProject?.secondPartName}}
                                    </p>
                                    <p id="projectShow_nav--thirdPartName"
                                       :class="`projectShow_nextProject${project.nextProject.id}_nav--thirdPartName`">
                                       {{project.nextProject?.thirdPartName}}
                                    </p>
                                    <img :class="`projectShow_nextProject${project.nextProject.id}_nav--picture`"
                                    :src="getImgUrlImageProjects(project.nextProject.slug,
                                    project.nextProject.image)"/>
                </router-link>
        </div>
    </div>

</template>
<script scoped>
    import gsap from 'gsap'
    import sourceData from '@/data/home_preview_projects.json'
    import projectShowScrollingAnimation from '@/assets/js/scrolling-animation/projectShowScrollingAnimation.js'
    import pictureLazyLoading from '@/assets/js/scrolling-animation/pictureLazyLoading.js'
    import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue'
    import lazyLoadingVanilla from '../assets/js/lazy-loading-images'
    // import imageHoverMovingMousePosition from '@/assets/js/image-grid-motion-effect/index.js'
    import Grid from '../assets/js/image-grid-motion-effect/grid.js';
    import { preloadImages } from '../assets/js/image-grid-motion-effect/utils.js';

    export default {
        name:"ProjectShow",
        components: {
            ScrollParallax,
        },
        // On définit la prop id de type string et obligatoire pour le fonctionnement de la route
        props: {
            // id:{
            //     type: String, required: true
            // },
            slug: {
                type: String, required: true
            }
        },
        computed:{
            project(){
                return sourceData.projects.find(project => project.slug === this.slug)
            }
        },
        methods: {
            getImgUrlImageProjects: function (projectName, imagePath) {
                return require('@/assets/img/projects/' + projectName + '/' + imagePath);
            },
            getImgUrlImageComparisonProjects: function (projectName, imagePath) {
                return require('@/assets/img/projects/' + projectName + '/comparison/' + imagePath);
            },
            test() {
                // Initialize grid = Calling Class instance syntax
                new Grid(document.querySelector('.projectShow__corpus'));
            },
        },
        mounted: function(){
            // Show all the page content during the transition
            document.body.style.opacity = 1

            // Nous appelons la méthode bind dans le composant cible
            // Nous écoutons l'évènement scrolls
            // Animation text projectName au scroll
            /*
             WARNING EVERY ELEMENT WITH THE CLASS panelProject
             MUST BE ASSOCIATED WITH THE DATA BINDING : data-color (sinon returned undefined color)
             */
            // homeAnimationScrolling.scrollingEffectProjectNext()
            projectShowScrollingAnimation.animation()
            pictureLazyLoading.animation()

            lazyLoadingVanilla.load()

            // console.log("this.$router                     =", this.$router)
            // console.log("this.$route                      =", this.$route)
            // console.log("this.$route.meta.transitionName  =", this.$route.meta.transitionName)

            // DOCUMENTATION - https://greensock.com/docs/v3/GSAP/Timeline/to()
            // Project title animation when the component has been mounted
            // Revealing Main project picture with animation for each project page
            var el = document.querySelector(".projectShow__image");
            el.style.opacity = 0

            var tl = gsap.timeline();
            tl.to(".projectShow__image",{duration: 3, opacity:1});

            // Project title animation when the component has been updated:
            var tl2 = gsap.timeline();
            tl2.to([".projectShow__FirstPart",".projectShow__SecondPart",".projectShow__ThirdPart"],{duration: .5, opacity:1});
            tl2.set([".projectShow__FirstPart"], {className: "projectShow__FirstPart projectShow__Text_Animate"});
            tl2.set([".projectShow__SecondPart"], {className: "projectShow__SecondPart projectShow__Text_Animate"});
            tl2.set([".projectShow__ThirdPart"], {className: "projectShow__ThirdPart projectShow__Text_Animate"});

            // Setting a pause of 1 second for date and location infos:
            tl2.to([".projectShow__Description--location",".projectShow__Description--date"],{duration: 1});

            tl2.set(".projectShow__Description--location", {className: "projectShow__Description--location fade-in-right-dateAndLocation"});
            tl2.set(".projectShow__Description--date", {className: "projectShow__Description--date fade-in-right-dateAndLocation"});

            // imageHoverMovingMousePosition.animation()

            preloadImages('.projectShow__picture').then(() => {
                // this.$route.params.slug
                // --- How to get the current route of the project ---
                // console.log("current route=", this.$route.params.slug)

                // Initialize grid = Calling Class instance syntax
                new Grid(document.querySelector('.projectShow__corpus'));
            });

        },
        updated(){
            // console.log("Update Project Show")

            pictureLazyLoading.animation()

            // Revealing projectShow__Name with animation for each project page
            const title = document.querySelector(".projectShow__Name");
            title.style.opacity = 0
            title.classList.remove("projectShow__Text_Animate")

            // Revealing Main project picture with animation for each project page
            var el = document.querySelector(".projectShow__image");
            el.style.opacity = 0

            // DOCUMENTATION - https://greensock.com/docs/v3/GSAP/Timeline/to()
            // Revealing Main project picture with animation for each project page
            // when the component has been updated:
            var tl = gsap.timeline();
            tl.to(".projectShow__image", {duration: 1, opacity:1});

            // Project title animation when the component has been updated:
            var tl2 = gsap.timeline();
            tl2.to([".projectShow__Name",".projectShow__FirstPart",".projectShow__SecondPart",".projectShow__ThirdPart"],{duration: .5, opacity:1});
            tl2.set([".projectShow__FirstPart"], {className: "projectShow__FirstPart projectShow__Text_Animate"});
            tl2.set([".projectShow__SecondPart"], {className: "projectShow__SecondPart projectShow__Text_Animate"});
            tl2.set([".projectShow__ThirdPart"], {className: "projectShow__ThirdPart projectShow__Text_Animate"});

            // We have created two gsap timelines to launch animation simultaneously

            tl2.set(".projectShow__Description--location", {className: "projectShow__Description--location fade-in-right-dateAndLocation"});
            tl2.set(".projectShow__Description--date", {className: "projectShow__Description--date fade-in-right-dateAndLocation"});

        },
        unmounted: function(){
        },
        created() {
            this.$watch(
            () => this.$route.params.slug,
                () => {
                // react to route changes...
                // console.log("this.$route.params.slug =", this.$route.params.slug)
              }
            )
        },
        // https://vuejs.org/api/options-lifecycle.html#unmounted
        // unmounted(){
        //     document.body.style.backgroundColor = "black"
        // }
    }
</script>


