import imagesLoaded from 'imagesloaded'
//const charming = require('charming')
//import anime from 'animejs/lib/anime.es.js';
import gsap from 'gsap'


//ScrollingLetterAnimation
var projectShowScrollingAnimation = {
    animation(){
        class Entry {
            constructor(el) {
                this.DOM = {el: el};
                observer.observe(this.DOM.el);
            }
        }

        //  We initializing these variables:
        let observer;
        // let current = -1;
        let allentries = [];

        // Preload all the images in the page..
        imagesLoaded(document.querySelectorAll('.projectShow__image'), () => {
            if ('IntersectionObserver' in window) {
                const bodyEl = document.body;

                observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if ( entry.isIntersecting ) {
                            // console.log("entry.target =", entry.target)
                            bodyEl.style.backgroundColor = entry.target.dataset.bgcolor;

                            if(entry.target.classList.contains("nextProject")){
                                // console.log("entry.target.classList =", entry.target.classList)
                                // console.log("entry.target.id =", entry.target.id)

                                var tl2 = gsap.timeline();
                                tl2.to("#projectShow_nav--next",{duration: .15, opacity:1});
                                tl2.set("#projectShow_nav--next", {className: "projectShow_nextProject" + entry.target.id + "_nav--next" + " projectShow__Text_Animate"});

                                // IMPORTANT WE MUST TARGET THE RIGHT CSS CLASSES SPECIFIC TO THE CURRENT NEXT PROJECT
                                tl2.set("#projectShow_nav--firstPartName", {className: "projectShow_nextProject" + entry.target.id + "_nav--firstPartName"  + " fade-in-right"});
                                tl2.set("#projectShow_nav--secondPartName", {className: "projectShow_nextProject" + entry.target.id + "_nav--secondPartName"   + " fade-in-right"});
                                tl2.set("#projectShow_nav--thirdPartName", {className: "projectShow_nextProject" + entry.target.id + "_nav--thirdPartName"  + " fade-in-right"});
                            }

                        }

                        // IMPORTANT: When target element is not longer visible
                        // We set the default background project color here
                        if (!entry.isIntersecting) {
                            // console.log("newcurrent entry exit =", entry.target)
                            const bodyEl = document.body;
                            const container = document.querySelector(".projectShow__container")
                            if(container){
                                bodyEl.style.backgroundColor = container.dataset.bgcolor;

                                // We have a buggy behaviour for this animation sometimes

                                // var tl3 = gsap.timeline();
                                // tl3.to(["#projectShow_nav--next",
                                //         "#projectShow_nav--firstPartName",
                                //         "#projectShow_nav--secondPartName",
                                //         "#projectShow_nav--thirdPartName"],{duration: .5, opacity:0});


                                // document.querySelector('#projectShow_nav--next').classList.remove('projectShow__Text_Animate');
                                // document.querySelector('#projectShow_nav--firstPartName').classList.remove('fade-in-right');
                                // document.querySelector('#projectShow_nav--secondPartName').classList.remove('fade-in-right');
                                // document.querySelector('#projectShow_nav--thirdPartName').classList.remove('fade-in-right');

                            }
                        }
                    });
                },
                { threshold: 0.5 }); // 50% visible
                // { threshold: 1   }); // 100% visible

                // We store in a Array, all the DOM elements that containes panelProjet inside their classList
                const sections = Array.from(document.querySelectorAll('.panelProject'));
                sections.forEach(section => allentries.push(new Entry(section)));
            }
        });
    }
}


export default projectShowScrollingAnimation
