import imagesLoaded from 'imagesloaded'
//const charming = require('charming')
//import anime from 'animejs/lib/anime.es.js';

var pictureLazyLoading = {
    animation(){
        class Entry {
            constructor(el) {
                this.DOM = {el: el};
                observer.observe(this.DOM.el);
            }
            enter(direction) {
                if(direction){
                    //const bodyEl = document.body;
                    // Change background color
                    // bodyEl.style.backgroundColor = this.DOM.el.dataset.bgcolor;
                    // console.log("this.DOM.el =", this.DOM.el)
                    // this.DOM.style.backgroundColor = this.DOM.el.dataset.bgcolor;
                    this.DOM.el.classList.add("projectShow__picture");
                    this.DOM.el.classList.add("projectShow__fade-in");
                }
            }
            exit(direction) {
                if(direction){
                    // const bodyEl = document.body;
                    // bodyEl.style.backgroundColor = this.DOM.el.dataset.bgcolor;
                }
            }
        }

        let observer;
        let current = -1;
        let allentries = [];
        const sections = Array.from(document.querySelectorAll('.projectShow__picture'));
        // Preload all the images in the page..
        imagesLoaded(document.querySelectorAll('.projectShow__picture'), () => {
            // document.querySelector('.scrollingLettersAnimation5__demo-5').classList.remove('scrollingLettersAnimation5__loading');
            if ('IntersectionObserver' in window) {
                // document.querySelector('.scrollingLettersAnimation5__demo-5').classList.add('scrollingLettersAnimation5__ioapi');

                observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        // console.log('entry =', entry)
                        // ratio 0 ==> wait full element on screen
                        if ( entry.intersectionRatio < .25 ) {
                            const newcurrent = sections.indexOf(entry.target);
                            if ( newcurrent === current ) return;
                            const direction = newcurrent > current;
                            if (current >= 0 ) {
                                allentries[current].exit(direction ? 'down' : 'up');
                            }
                            allentries[newcurrent].enter(direction ? 'down' : 'up');
                            current = newcurrent;
                        }
                    });
                }, { threshold: 0.5 });

                sections.forEach(section => allentries.push(new Entry(section)));
            }
        });
    }
}


export default pictureLazyLoading